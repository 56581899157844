$springGreen: #95E1BF;
$radioGreen: #9DDEC0;
$forestGreen: #21362C;
$purple: #4D2A98;

$textShadow: 0px 0px 2px rgba(149, 225, 191, 0.8);
$border: 0.1rem solid rgba(250, 250, 250, 0.2);

.communication {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes radioBar {
        0% {
            heiht: 0rem;
        }
        100% {
            height: 20rem;
        }
    }

    @mixin animate {
        animation-name: fadeIn;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
    }

    .wrapper {
        width: 90rem;
        display: flex;
        flex-direction: column;
        .buttonRow {
            display: flex;
            justify-content: center;
        }
        button {
            border: 0.2rem solid $springGreen;
            background: transparent;
            color: $springGreen;
            padding: 1.4rem 2rem;
            margin-top: 3rem;
            font-size: 2rem;
            font-weight: 600;
            text-shadow: 0px 0px 2px rgba(149, 225, 191, 0.4);
            margin-left: auto;
            margin-right: auto;
            opacity: 0;
            @include animate;
            animation-delay: 6s;
        }
        h2 {
            font-size: 2.6rem;
            padding-top: 0.8rem;
            padding-bottom: 1rem;
            font-variant: small-caps;
            font-variant: all-small-caps;
            letter-spacing: 0.06em;
            font-weight: 600;
            border-top: $border;
            border-bottom: $border;
            text-align: center;
            text-shadow: $textShadow;
            opacity: 0;
            @include animate;
            animation-delay: 0.5s;
        }

        .messageRow {
            display: flex;
            border-bottom: $border;
            padding-top: 1rem;
            padding-bottom: 1rem;
            opacity: 0;
            @include animate;
            animation-delay: 0.5s;
            .profile {
                margin-right: 2rem;
                text-align: center;
                opacity: 0;
                animation-delay: 2.5s;
                @include animate;
                p {
                    color: #DEDEDE;
                    font-size: 2rem;
                    font-weight: 700;
                }
            }
            .message {
                display: flex;
                .radioWrapper {
                    position: relative;
                    width: 24rem;
                    height: 26rem;
                    animation-delay: 2s;
                    opacity: 0;
                    @include animate;
                    .radio {
                        height: 24rem;
                        width: 24rem;
                        position: relative;

                        .bars {
                            height: 100%;
                            width: 100%;
                            background-color: #3f584d;
                        }
                        .activeIndication {
                            width: 100%;
                            height: 0;
                            position: absolute;
                            background-color: $springGreen;
                            bottom: -1rem;
                            animation-name: radioBar;
                            animation-duration: 0.6s;
                            animation-delay: 2.5s;
                            animation-fill-mode: forwards;
                        }
                    }
                    p {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 1.5rem;
                        left: 6rem;
                        color: #BAF3D9;
                        font-weight: 500;
                        font-size: 2.5rem;
                        text-shadow: $textShadow;
                    }
                }
                .radioMessage {
                    font-size: 3.4rem;
                    color: white;
                    text-shadow: $textShadow;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    margin-left: -6rem;
                    opacity: 0;
                    @include animate;
                    animation-delay: 4s;
                }
            }
        }
    }
}
