html {
    font-size: 62.5%;
}

body {
    background-color: #eee;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Montserrat", sans-serif;
    font-size: 2.4rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

ul,
li {
  margin: 0;
  padding: 0;
}

.page {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 3.2rem;
    margin-left: 2rem;
}

.carousel {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    display: flex;
    background-color: white;
}

.carouselItem {
    width: 100%;
    height: 100%;
    padding: 3rem 8rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    overflow: hidden;
    box-sizing: border-box;
}

.carouselItem > div {
    width: 100%;
    height: 100%;
    min-height: 50rem;
    margin: 1rem;
}

.carousel.carousel-slider {
  height: 100%;
  width: 100%;
}