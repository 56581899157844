$springGreen: #95E1BF;
$forestGreen: #21362C;
$purple: #4D2A98;
$burntOrange: #B44A06;
$silverGray: #6F7271;
$cloudyGray: #DFE1DF;
$sunsetOrange: #FFA621;
$blue: #3479bd;
$green: #40a251;
$orange: #D1B464;
$lightGray: #f2f2f2;
$red: $burntOrange;
$dashboardBlack: #26303F;
$buttonColor: $purple;
$buttonTextColor: white;
$textColor: $forestGreen;
$borderRadius: 0.3rem;

$purpleFade: lighten($purple, 60%);
$redFade: rgba(255, 0, 0, 0.05);
$greenFade: lighten($green, 50%);

@mixin phone {
    @media (max-width: 800px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 801px) {
        @content;
    }
}


//Box-shadow
@mixin buttonShadow {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

@mixin modalShadow {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

@mixin corneredAngle($color) {
    .corneredAngle {
        $width: 3rem;
        display: block;
        width: 0;
        height: $width;
        margin-left: $width;
        margin-right: -0.1rem;
        margin-top: 0;
        margin-bottom: 0;
        background-color: $color;

        &:before {
            content: "";
            display: block;
            position: relative;
        }

        &.bl:before {
            left: -1 * $width;
            border-top: $width*0.5 solid transparent;
            border-right: $width*0.5 solid $color;
            border-bottom: $width*0.5 solid $color;
            border-left: $width*0.5 solid transparent;
        }

        &.bl:after {
            content: "";
            display: block;
            position: relative;
            border-bottom: 2px solid $color;
            width: 3rem;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: 0.2s;
        }
    }
}

@mixin messageAlert($backgroundColor, $textColor) {
    .messageText {
        background-color: $backgroundColor;
        color: $textColor;
        display: inline-flex;
        border-radius: 0.2rem 0 0 0;
        align-items: center;
        border-bottom: 2px solid $backgroundColor;
        border-right: 2px solid $backgroundColor;
        transition: 0.2s;
    }
    @include corneredAngle($backgroundColor);
}

.ad {
    --ad-soldout-color: #de9998;
    --ad-disabled-color: #d9d9d9;
    --ad-disabled-text-color: #9a9a9a;
    --ad-constraint-color: #bcbcbc;

    font-family: "Montserrat";

    background-color: white;
    border-radius: 0.3rem;
    padding: 1.9rem 2.7rem 1.5rem 2.7rem;
    width: 34rem;
    height: 24rem;
    transition: 0.2s;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    transform-origin: center center;
    &:not(.preview) {
        cursor: pointer;
    }

    @media (max-width: 800px) {
        width: 34rem;
    }
}

.disabled {
    cursor: auto;
    opacity: 0.5;
    box-shadow: none;
}

.disabled:hover {
    box-shadow: none;
}

.not__clickable:hover {
    box-shadow: none;
    cursor: auto;
}

.soldout {
    display: flex;

    p {
        color: $red;
        font-weight: 600;
        font-size: 2.4rem;
        margin: 0;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        font-size: 2.4rem;
        font-variant: small-caps;
        text-transform: lowercase;
        letter-spacing: 0.03em;
        line-height: 1;

        @media (max-width: 800px) {
            font-size: 2rem;
        }
    }
}

.name {
    width: 100%;
    margin: 0 0 1.6rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    color: $forestGreen;
    display: flex;
    align-items: center;
    
    .bankIcon {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
        background-color: $lightGray;
        margin-right: 0.8rem;

        &.checkIconInside {
            background-color: $greenFade;
        }
        
        i {
            font-weight: 500;
            font-size: 1.6rem;
        }
    }

    .nameContainer {
        display: flex;
        align-items: center;

        .bankName {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }
    }

    .capital {
        display: flex;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: -0.2rem;
        color: $silverGray;
        i {
            font-size: 1.2rem;
        }
    }
}

.interestgroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2.8rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .interest {
        font-size: 3.6rem;
        font-weight: 800;
        line-height: 1;
        margin: 0;
        color: $purple;
    }

    .nibor {
        font-weight: 600;

        .niborLabel {
            font-size: 1.2rem;
        }

        .niborValue {
            margin-left: 0.6rem;
            border-left: 1px solid rgba(0,0,0,0.1);
            padding-left: 0.6rem;
            font-size: 1.4rem;
        }
    }
}

.constraints {
    margin: 0;
    font-size: 1.4rem;
    margin-bottom: 0.6rem;
    padding-top: 1.4rem;
    color: $forestGreen;
}

.constraints__range {
    height: 2px;
    background: $cloudyGray;
    margin-bottom: 0.4rem;

    .constraints__range__selected {
        background: $sunsetOrange;
        height: 2px;
        position: relative;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: $sunsetOrange;
            border-radius: 10px;
            position: absolute;
            top: -4px;
            opacity: 0.6;
        }

        &:before {
            left: 0;
            margin-left: -5px;
        }

        &:after {
            right: 0;
            margin-right: -5px;
        }
    }
}

.constraint__label__hidden {
    color: white;
}

.product {
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 1.4rem;
}

.message {
    display: inline-flex;
    flex-direction: row;
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: 500;
    position: absolute;
    right: -0.2rem;
    bottom: -0.2rem;
    margin: 0;
    font-weight: 600;
    overflow-y: hidden;
    align-items: stretch;

    i {
        font-size: 1.8rem;
        font-weight: normal;
    }

    .text {
        padding: 0.4rem 1.2rem;
        align-items: center;
    }

    i + .text {
        padding-left: 0.4rem;
    }
}

.productInfo {
    display: flex;

    .constraints {
        display: flex;
        flex-direction: column;
        width: 50%;

        .contstraints__title {
            color: $silverGray;
        }

        .constraint__value {
            font-size: 1.6rem;
            font-weight: 700;
            margin-top: 0.2rem;
        }
    }
}

.message {
    @include messageAlert(lighten($springGreen, 15), $forestGreen)
}

.message__alert {
    @include messageAlert(lighten($red, 55), $red)
}

.message__new {
    @include messageAlert(lighten($springGreen, 15), $forestGreen)
}

.message__special {
    @include messageAlert($forestGreen, $springGreen)
}

@include desktop {
    .ad:not(.disabled, .preview):hover {
        transform: scale(1.02);
        box-shadow: 0 0.6rem 2rem rgba(0, 0, 0, 0.3);
        border: 2px solid $purple;

        .messageText {
            border-color: $purple;
        }
       
        .corneredAngle {
            &.bl:after {
                border-color: $purple;
            }
        }

    }
}

@include phone {
    .ad {
        padding: 1.9rem 2rem 1.5rem 2rem;

        .interestgroup {
            .interest {
                font-size: 3.2rem;
            }
        }
    }
}