.blackBoard {
    h3 {
        color: rgba(250,250,250,0.9);
        font-size: 2.2rem;
        font-weight: 400;
        margin: 0;
    }
    p {
        color: rgba(250,250,250,0.9);
        font-size: 3.2rem;
        font-weight: 700;
        margin: 0;
    }
}