header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4rem;
    padding-left: 8rem;
    padding-right: 8rem;
    width: 100%;
    .logo {
        width: 18rem;
    }
    .chipContainer {
        .chip {
            background-color: #333;
            display: inline-flex;
            margin-left: 2rem;
            align-items: center;
            font-size: 1.4rem;
            border-radius: 0.5rem;
            font-weight: 600;
            padding: 1rem 1.4rem;
            color: white;
            &.disabled {
                background-color: #666;
            }
            .circle {
                width: 1.4rem;
                height: 1.4rem;
                border-radius: 100px;
                display: flex;
                margin-right: 1rem;
                &.success {
                    background-color: #49A759;
                }
                &.error {
                    background-color: #B44A06;
                }
            }
            &.disabled {
                .circle {
                    &.success,
                    &.error {
                        background-color: orange;
                    }
                    background-color: orange;
                }
            }
        }
    }
}

.tree,
.bench,
.bin,
.light {
    margin-left: 6rem;
    margin-right: 6rem;
}

.vault {
    width: 80rem;
    background-image: url('./images/vault.svg');
    display: flex;
}

.hide {
    visibility: hidden;
    position: fixed;
    right: 0;
    top: 0;
}

.cinema {
    position: relative;
    display: flex;
    width: 110rem;
    .dashboardContainer {
        position: absolute;
        width: 100%;
        height: 78%;
        top: 2.3rem;
        background-color: white;
        > div {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            font-size: 62.5%;
        }
    }
    .screen {
        width: 100%;
        max-height: 100%;
    }
    .car {
        width: 10rem;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        z-index: 1;
    }
    .people {
        width: 100%;
        position: absolute;
        display: flex;
        bottom: 0;
        height: 50%;
        align-items: flex-end;
    }
}

.weather {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    pointer-events: none;

    &.gloomy {
        background-color: rgba(0,0,0,0.1);
    }

    .rain {
        display: flex;

        > div {

            &:nth-child(2) {
                transform: translateY(100px);
            }
            &:nth-child(3) {
                transform: translateY(-200px);
            }
            &:nth-child(4) {
                transform: translateY(250px);
            }
        }

        .front {
            display: flex;
            @keyframes rain {
                0% {
                    transform: translateY(-100px)
                } 100% {
                    transform: translateY(100vh)
                }
            }
            &::before,
            &::after {
                content: '';
                width: 2px;
                background-color: white;
                display: flex;
                margin-right: 5rem;
                animation-name: rain;
                animation-duration: 0.8s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
            &::before {
                height: 150px;
                animation-delay: 0.5s;
                opacity: 0.6;
            }
            &::after {
                height: 100px;
                opacity: 0.5;
            }
        }

        &.light {
            .front {
                &::before {
                    opacity: 0.3;
                }
                &::after {
                    opacity: 0.2;
                }
            }
        }
    }
}

.moneyDrop {
    position: fixed;
    left: 50%;
    transform: translate(-200px, 0);
    height: calc(100% - 353px);
    @keyframes drop {
        0% {
            background-position-y: top;
            opacity: 1;
        } 100% {
            background-position-y: bottom;
            opacity: 1;
        }
    }
    img {
        position: absolute;
        left: 0;
        animation: drop 1s ease-in;
        animation-fill-mode: forwards;
        opacity: 0;
    }
    div[data-id=money-bag] {
        width: 60px;
        height: 100%;
        position: absolute;
        left: 0;
        animation: drop 1s ease-in;
        animation-fill-mode: forwards;
        background-size: contain;
        background-image: url('./images/money-bags/money-bag.png');
        background-repeat: no-repeat;
        opacity: 0;

        &.bigMoney {
            width: 120px;
            background-image: url('./images/money-bags/big-money-bag.png');
        }
    }
}

.planeLineWrapper {
    position: fixed;
    top: 15rem;
    z-index: 1;
    .planeWithBanner {
        display: flex;
        align-items: center;
        transform: translateX(-100vw) translateY(0);
        animation: flyOver linear 20s;
        width: 1000px;
        @keyframes flyOver {
            0% {
                transform: translateX(-100vw) translateY(-50px);
            } 50% {
                transform: translateX(calc(50vw - 1100px)) translateY(100px);
            } 100% {
                transform: translateX(100vw) translateY(50px);
            }
        }

        .banner {
            background-color: white;
            padding-left: 6rem;
            padding-right: 6rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
            display: flex;
            align-items: center;
            h2 {
                margin-bottom: 0;
                margin-top: 0;
                font-weight: 800;
                font-size: 3rem;
                text-transform: uppercase;
                letter-spacing: 0.3rem;
            }
            p {
                margin-bottom: 0;
                margin-top: 1rem;
                font-size: 2.4rem;
            }
        }
        img {
            transform: translate(-50px, 0);
        }
        .line {
            width: 25rem;
            height: 0.2rem;
            background-color:rgba(0,0,0,0.2);
        }
    }
}

.city {
    height: 100%;
    min-width: 100%;

    .street {
        background-color: #D4EDFF;
        width: 100%;
        height: 100%;
        margin-top: auto;
        margin-bottom: 6.7rem;
        background-image: url('./images/road-tile.jpg'),
        url('./images/city-background.png');
        background-repeat: repeat no-repeat, repeat no-repeat, repeat no-repeat;
        background-position: 100% 100%, 100% calc(100% - 60px), 100px 100px;
        background-size: 60px, 600px, 1600px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        &.cloudy {
            background-image: url('./images/road-tile.jpg'),
            url('./images/city-background.png'),
            url('./images/Clouds.svg');
        }

        .fixrateArea {
            margin-top: auto;
            margin-bottom: 6.7rem;
            padding-left: 10rem;
            padding-right: 20rem;
            display: flex;
            align-items: flex-end;
            .headQuarter {
                height: 60rem;
                padding-right: 10rem;
            }
        }

        .banks,
        .stockExchange,
        .depositors {
            margin-top: auto;
            margin-bottom: 6.6rem;
            display: flex;
            list-style: none;
            margin-left: 10rem;
            margin-right: 10rem;
            li {
                margin-right: 8rem;
                display: flex;
                position: relative;
                justify-content: center;
                &.small {
                    width: 20rem;
                    h3 {
                        bottom: 27rem;
                        width: 18rem;
                        color: white;
                        font-weight: 600;
                        font-size: 1.2rem;
                    }
                }
                &.big {
                    width: 50rem;
                    h3 {
                        bottom: 37rem;
                        width: 20rem;
                        color: #333;
                    }
                }
                h3 {
                    position: absolute;
                    text-align: center;
                    font-size: 1.4rem;
                    white-space: nowrap;
                    text-overflow: clip;
                    overflow: hidden;
                }
                img {
                    margin-top: auto;
                    max-width: 100%;
                }
            }
            .chopper {
                position: absolute;
                bottom: 70rem;
                transform: translate(0, -100vh) rotate(3deg);
                transition: 5s;
                &.show {
                    transform: translate(0, 0) rotate(-3deg);
                }
            }
            &.testMode {
                background-color: rgba(236, 93, 93, 0.5);
                li {
                    background-color: rgba(236, 93, 93, 0.5);
                }
            }
        }
    }
}

.night {
    .street {
        background-color: #112330;
    }
}

.headQuarterWrapper {
    position: relative;
    display: flex;
    .alertLamp {
        position: absolute;
        right: 13rem;
        top: 5.5rem;
    }
    .alertLampAll {
        position: absolute;
        right: 66rem;
        top: 24rem;
    }
}
